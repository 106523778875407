













































@import '../../utilities/config';

.o-accdoc {
  //margin: 15px 0;
  &__grid {
    //margin-bottom: 30px;
    border: 1px solid gray('gray-150');
    border-bottom: 0;
    &__title {
      font-size: 16px;
      display: block;
    }
  }
  &__row {
    @media screen and (min-width: breakpoint-min('md')) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.75rem 0;
    }
    border-bottom: 1px solid gray('gray-150');
    //cursor: pointer;
    position: relative;
    padding: 0.75rem 60px 0.75rem 0;
    background-color: gray('white');
  }
  &__col {
    padding: 0 0.75rem;
    font-size: 14px;
    min-width: 25%;

    &--download {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      @media screen and (min-width: breakpoint-min('md')) {
        position: relative;
        transform: translateY(0%);
      }
    }
  }
}
