



















































































































































































@import '../../utilities/config';
.dropzone {
  margin-top: 16px;
  padding: 0;
  border: 0;
  min-height: auto;
  background: transparent;

  .dropzone {
    background-color: color('white');
    border: 2px dotted theme-color('primary');
    border-radius: 4px;
    padding: 16px;
    min-height: auto;

    .dz-message {
      margin: 2em 0;
    }

    .dz-default u {
      color: $link-color;
      &:hover {
        color: $link-hover-color;
      }
    }

    .dz-preview {
      background: theme-color('primary');
      border-radius: 4px;
      color: color('white');
      font-size: 14px;
      padding: 8px;
      position: relative;
      display: block;
      margin: 0;
      min-height: auto;
      strong {
        color: inherit;
      }
      & + & {
        margin-top: 16px;
      }
      &__details {
        align-items: center;
        display: flex;
        flex: 0 0 auto;
        width: 100%;
      }
      &__name {
        display: block;
        flex: 1 1 auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &__size {
        display: block;
        flex: 0 0 auto;
        margin-left: 8px;
      }
      &__remove {
        //@include btn-default-properties;
        background: transparent;
        border: none;
        border-radius: 0;
        color: inherit;
        cursor: pointer;
        padding: 0;
        text-decoration: none;
        user-select: none;
        white-space: normal;

        display: block;
        flex: 0 0 auto;
        background: url(../../assets/close_white.svg) no-repeat 50% 40%;
        background-size: auto 12px;
        height: 1.5em;
        width: 1.5em;
        margin: 0 -3px 0 5px;
      }
      &__progress {
        border: 1px solid theme-color('faded-green');
        border-radius: 9px;
        height: 8px;
        margin: 4px 0;
        &-upload {
          background: theme-color('faded-green');
          border-radius: 9px;
          height: 6px;
          width: 0;
        }
      }
    }
  }
  &__btn {
    margin-top: 15px;
    text-align: center;
    > :last-child {
      margin-bottom: 0;
    }
  }
}
