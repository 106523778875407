
























@import '../../utilities/config';
.box__header--icon-exclamation:before {
  background-image: url(../../assets/error_circle_red.svg);
  background-color: gray('white'); //prevent icon see-through
  border-radius: 100px;
}
.box__header--icon-info-warning:before {
  background-image: url(../../assets/error_circle_orange.svg);
  background-color: gray('white'); //prevent icon see-through
  border-radius: 100px;
  transform: rotate(180deg);
}
