






































































































































































































































@import '../utilities/config';

.page__header__title {
  &::after {
    background-image: url(../assets/accreditation_gray.svg);
  }
}
