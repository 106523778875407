













@import '../../utilities/config';
.a-crest {
  border-radius: 50%;
  background-color: gray('gray-200');
  /*height: 85px;
  width: 85px;
  min-width: 85px;
  max-width: 85px;
  min-height: 85px;
  max-height: 85px;*/

  font-size: 40px;

  height: 2.1em;
  width: 2.1em;
  min-width: 2.1em;
  max-width: 2.1em;
  min-height: 2.1em;
  max-height: 2.1em;

  margin: 0;
  color: gray('white');
  display: block;
  text-align: center;
  //line-height: 85px;
  line-height: 2.1em;
  box-sizing: border-box;
  position: relative;

  &--primary {
    background-color: theme-color('primary');

    &-bordered {
      //line-height: 81px;
      line-height: 1.95em;
      background-color: transparent;
      color: theme-color('primary');
      border: 0.09em solid theme-color('primary');
    }
  }
  &--gray {
    background-color: gray('gray-400');

    &-bordered {
      //line-height: 81px;
      line-height: 1.95em;
      background-color: transparent;
      color: gray('gray-400');
      border: 0.09em solid gray('gray-400');
    }
  }
  &--warning {
    background-color: theme-color('warning');

    &-bordered {
      //line-height: 81px;
      line-height: 1.95em;
      background-color: transparent;
      color: theme-color('warning');
      border: 0.09em solid theme-color('warning');
    }
  }
  &--danger {
    background-color: theme-color('danger');

    &-bordered {
      //line-height: 81px;
      line-height: 1.95em;
      background-color: transparent;
      color: theme-color('danger');
      border: 0.09em solid theme-color('danger');
    }
  }
  &--error {
    background-color: theme-color('orange');
  }
  &--accreditation {
    background-image: url(../../assets/accreditation_white.svg);
    background-position: center center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
  }
  &__label {
    font-weight: 500; //font-weight: 400;;
    text-transform: uppercase;
    font-size: 12px;
    position: absolute;
    display: block;
    left: 50%;
    top: 53px;
    transform: translateX(-50%);
    line-height: 10px;
  }

  &--sm {
    font-size: 28px;
    & > .a-crest__label {
      font-size: 10px;
      top: 35px;
    }
  }
  &--haslabel {
    line-height: 1.7em;
    &.a-crest--sm {
      line-height: 1.5em;
    }
  }
}
