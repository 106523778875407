















































































































































































































































































































































@import '../../utilities/config';
.o-accdetail {
  &__reportDueDate {
    font-size: 1.35em;

    &--ispast {
      color: theme-color('warning');
    }
  }

  &__creastbox {
    display: flex;
    align-items: center;
    & > :first-child {
      margin-top: 0;
    }
    & > :last-child {
      margin-bottom: 0;
    }
    &__text {
      &:first-child {
        padding-left: 0;
      }
      padding-left: 25px;
      & > :first-child {
        margin-top: 0;
      }
      & > :last-child {
        margin-bottom: 0;
      }
    }
  }

  // Jedi: new = good
  &__box-header-with-creast {
    padding-right: 110px;
    min-height: 84px;
  }
  &__box-header-creast {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
  }

  &__xs-creast {
    display: inline-block;
    border-radius: 100%;
    background-color: theme-color('primary');
    height: 20px;
    width: 20px;
    line-height: 20px;
    color: gray('white');
    text-align: center;
    font-weight: 500; //font-weight: 400;;
    margin-right: 10px;

    &--doubledigit {
      font-size: 0.75em;
    }

    &--gray {
      background-color: gray('gray-400');
    }
  }
  &__rate {
    color: gray('gray-400');
  }
  &__ratings {
    margin-bottom: 20px;
    &-link {
      display: inline-block;
      margin-left: 10px;
    }
  }
}
